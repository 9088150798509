import * as React from 'react'
import { Head, Header } from '@inc/index'
import NavBar from "@components/NavBar"

const Layout = ({ children, }) => {

  return (
    <>
      <Head />
      <Header />
      <NavBar />
      {children}
    </>
  )
}
export default Layout