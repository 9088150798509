import * as React from 'react'
import { useLocation } from "@reach/router"
import Helmet from "react-helmet"

const Head = () => {

  const locationHref = useLocation().href
  const locationPathname = useLocation().pathname;
  console.log(locationHref);
  console.log(locationPathname);

  let title = "";
  let description = "description";
  let keywords = "keywords";
  let ogSiteName = "ogSiteName";
  let ogTitle = "";
  let ogDescription = description;
  let ogType = "";
  let ogLocale = "ja_JP";
  let ogUrl = locationHref;
  let ogImage = "ogImageUrl";

  if(locationPathname === "/"){
    title = "index";
    ogTitle = title;
    ogType = "website";
  }else if(locationPathname === "/contact"){
    title = "contact";
    ogTitle = title;
    ogType = "article";
  }

  return (
    <>
      <Helmet>
        <html lang="ja" prefix="og: https://ogp.me/ns#" />
        <title>{title}</title>
        <meta http-equiv="Content-Style-Type" content="text/css" />
        <meta http-equiv="Content-Script-Type" content="text/javascript" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:locale" content={ogLocale} />
        <meta property="og:site_name" content={ogSiteName} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content={ogType} />
        <meta property="og:title" content={ogTitle} />
      </Helmet>
    </>
  )
}
export default Head